import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export {APILayout};

function APILayout(props: {

  children: any;
}) {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  const [extended, setExtended] = useState("");

  return (<div className={"api-layout"}>

    <div className={"api-layout__navigation"}>

      <h1>API Reference</h1>

      <ul className={"api-layout__navigation__list"}>
        <li className={`${appContext.appNavigation.page === "APILandingPage" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/'}>
            + Introduction
          </Link>
        </li>
        <li className={`${appContext.appNavigation.page === "APITeams" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/teams'}>
            + Teams
          </Link>
        </li>
        <li className={`${appContext.appNavigation.page === "APIJobs" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/jobs'}>
            + Jobs
          </Link>
        </li>
        <li className={`${appContext.appNavigation.page === "APICandidacies" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/candidacies'}>
            + Candidacies
          </Link>
        </li>
        <li className={`${appContext.appNavigation.page === "APICommunities" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/communities'}>
            + Communities
          </Link>
        </li>
        <li className={`${appContext.appNavigation.page === "APICandidates" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/candidates'}>
            + Candidates
          </Link>
        </li>
        <li className={`${appContext.appNavigation.page === "APIAmbassadors" ? "api-layout__navigation__list--selected" : ""}`}>
          <Link to={'/ambassadors'}>
            + Ambassadors
          </Link>
        </li>
        {/*<li className={`${appContext.appNavigation.page === "APIOther" ? "api-layout__navigation__list--selected" : ""}`}>*/}
        {/*  <Link to={'/other'}>*/}
        {/*    + Other cases*/}
        {/*  </Link>*/}
        {/*</li>*/}
      </ul>

    </div>

    <div className={"api-layout__content"}>
      {props.children}
    </div>

  </div>);
}