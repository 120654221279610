/**
 * All the routes here are generated in the Routing component
 * public are the routes that don't need authentication to access
 * private are routes that needs authentication to access
 * shared are routes that should always be available to anyone (example: a callback sent by email should point here)
 * Public and private routes MUST have a route pointing to the path "/", but right now the landing pages are not taken from here, should be refactored
 */
import {APILandingPage} from "../components/publicSpacePages/APILandingPage"
import {FunctionComponent} from "react";
import {APITeams} from "../components/publicSpacePages/APITeams";
import {APIJobs} from "../components/publicSpacePages/APIJobs";
import {APIOther} from "../components/publicSpacePages/APIOther";
import {APIAmbassadors} from "../components/publicSpacePages/APIAmbassadors";
import {APICommunities} from "../components/publicSpacePages/APICommunities";
import {APICandidacies} from "../components/publicSpacePages/APICandidacies";
import {APICandidates} from "../components/publicSpacePages/APICandidates";

export {routes};

export interface IRoute {
  path: string;
  page: string;
  component: FunctionComponent<any>;
  exact: boolean;
}

interface IRoutes {
  public: IRoute[];
  shared: IRoute[];
  private: IRoute[];
}

const routes: IRoutes = {
  public: [],
  shared: [],
  private: [],
};

// --------------------------- Public routes
routes.public.push({exact: true, path: '/', page: 'APILandingPage', component: APILandingPage});
routes.public.push({exact: true, path: '/teams', page: 'APITeams', component: APITeams});
routes.public.push({exact: true, path: '/jobs', page: 'APIJobs', component: APIJobs});
routes.public.push({exact: true, path: '/candidacies', page: 'APICandidacies', component: APICandidacies});
routes.public.push({exact: true, path: '/communities', page: 'APICommunities', component: APICommunities});
routes.public.push({exact: true, path: '/candidates', page: 'APICandidates', component: APICandidates});
routes.public.push({exact: true, path: '/ambassadors', page: 'APIAmbassadors', component: APIAmbassadors});
routes.public.push({exact: true, path: '/other', page: 'APIOther', component: APIOther});



