
import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APIAmbassadors};

const APIAmbassadors = (props: any) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  return(<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>

      <h1 className={"api-layout__title"}>
        Ambassadors
      </h1>

      An <span className={"text--i text--label--grey"}>ambassador</span> is an entity attached to the <span className={"text--i text--label--grey"}>business</span>. They made <span className={"text--i text--label--grey"}>candidates</span> apply.

      <p><br/></p>

      {/*------------------------------------------------------------------------*/}
      <hr/>

      <h1 className={"api-layout__title_2"}>
        Get all ambassadors
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /ambassadors
      </div>

      <p><br/></p>

      Headers: The request do not need headers other than the token.

      <p><br/></p>

      {/*Answer:*/}
      {/*<p><br/></p>*/}
      {/*<table>*/}
      {/*  <tr>*/}
      {/*    <td>*/}
      {/*      jobs_opened*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      array*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      Contains an array of all teams*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*  <tr>*/}
      {/*    <td>*/}
      {/*      job_id*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      string*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      12 bites ID, unique to every team*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*</table>*/}

      <p><br/></p>

      <hr/>

      To come:

      <p><br/></p>

      <ul className={"default-list"}>
        <li>
          Create an ambassador
        </li>
        <li>
          Get all candidates an ambassador gave you
        </li>
      </ul>

    </APILayout>

  </PageLayout>);

};

