
import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APICandidates};

const APICandidates = (props: any) => {

  return(<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>

      <h1 className={"api-layout__title"}>
        Candidates
      </h1>

      A <span className={"text--i text--label--grey"}>candidate</span> is an entity holding personal informations on a person in a <span className={"text--i text--label--grey"}>community</span>. A <span className={"text--i text--label--grey"}>candidate</span> can have multiple <span className={"text--i text--label--grey"}>candidacies</span>.

      <p><br/></p>

      {/*------------------------------------------------------------------------*/}
      <hr/>

      <h1 className={"api-layout__title_2"}>
        Get all candidates for a community
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /candidates
      </div>

      <p><br/></p>

      Headers:

      <table>
        <tr>
          <td>
            community_id
          </td>
          <td>
            string
          </td>
          <td>
            The ID of the community you want to check.
          </td>
        </tr>
      </table>

      <p><br/></p>

      <hr/>

      To come:

      <p><br/></p>

      <ul className={"default-list"}>
        <li>
          Get a specific candidate
        </li>
        <li>
          Modify a candidate
        </li>
        <li>
          Create a candidate
        </li>
      </ul>

    </APILayout>

  </PageLayout>);

};

