import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APIJobs};

const APIJobs = (props: any) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  return (<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>

      <h1 className={"api-layout__title"}>
        Jobs
      </h1>

      A <span className={"text--i text--label--grey"}>job</span> is an entity holding <span
      className={"text--i text--label--grey"}>candidacies</span>. <br/><br/>Be careful, <span
      className={"text--i text--label--grey"}>candidacies</span> are attached to a <span
      className={"text--i text--label--grey"}>job</span>, and <span
      className={"text--i text--label--grey"}>candidates</span> are attached to a <span
      className={"text--i text--label--grey"}>community</span>.

      <p><br/></p>

      {/*------------------------------------------------------------------------*/}
      <hr/>

      <h1 className={"api-layout__title_2"}>
        Get all jobs
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /jobs
      </div>

      <p><br/></p>

      Headers:

      <table>
        <tr>
          <td>
            team_id *
          </td>
          <td>
            string
          </td>
          <td>
            This variable is not mandatory. use it to get jobs of a specific team.
          </td>
        </tr>
      </table>

      <p><br/></p>

      {/*Answer:*/}
      {/*<p><br/></p>*/}
      {/*<table>*/}
      {/*  <tr>*/}
      {/*    <td>*/}
      {/*      jobs_opened*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      array*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      Contains an array of all teams*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*  <tr>*/}
      {/*    <td>*/}
      {/*      job_id*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      string*/}
      {/*    </td>*/}
      {/*    <td>*/}
      {/*      12 bites ID, unique to every team*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*</table>*/}

      <p><br/></p>

      <hr/>

      To come:

      <p><br/></p>

      <ul className={"default-list"}>
        <li>
          Post a job
        </li>
        <li>
          Modify a job
        </li>
      </ul>

    </APILayout>

  </PageLayout>);

};

