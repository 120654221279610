import React, {Fragment, useContext} from "react";

export {NavBar};

function NavBar() {

  return(<div className={"navbar"}>
    <div className={"navbar__left"}>
      <div className={"navbar__logo"}>
        MS
      </div>
      <div className={"navbar__title"}>
        Developers
      </div>
    </div>
    <div className={"navbar__right"}>
      <div className={"navbar__menu"}>
        API Reference
      </div>
    </div>

  </div>);
}