import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APICandidacies};

const APICandidacies = (props: any) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  return (<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>

      <h1 className={"api-layout__title"}>
        Candidacies
      </h1>

      A <span
      className={"text--i text--label--grey"}>candidacy</span> holds informations on the person who applied to a <span
      className={"text--i text--label--grey"}>job</span>. Note that personal informations are attached to the <span
      className={"text--i text--label--grey"}>candidate</span> entity. A <span
      className={"text--i text--label--grey"}>candidate</span> can have many <span
      className={"text--i text--label--grey"}>candidacies</span>.

      <p><br/></p>

      {/*------------------------------------------------------------------------*/}
      <hr/>

      <h1 className={"api-layout__title_2"}>
        Get all candidacies for a job
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /candidacies
      </div>

      <p><br/></p>

      Headers:

      <table>
        <tr>
          <td>
            job_id
          </td>
          <td>
            string
          </td>
          <td>
            The ID of the job you want to check.
          </td>
        </tr>
        <tr>
          <td>
            column_id *
          </td>
          <td>
            string
          </td>
          <td>
            This variable is not mandatory. This is the ID of a specific column you want to check.
          </td>
        </tr>
      </table>

      <p><br/></p>


      {/*------------------------------------------------------------------------*/}
      <hr/>

      <h1 className={"api-layout__title_2"}>
        Get all unregistered candidacies for a job
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /candidacies/unregistered
      </div>

      <p><br/></p>

      Headers:

      <table>
        <tr>
          <td>
            job_id
          </td>
          <td>
            string
          </td>
          <td>
            The ID of the job you want to check.
          </td>
        </tr>
        <tr>
          <td>
            column_id *
          </td>
          <td>
            string
          </td>
          <td>
            This variable is not mandatory. This is the ID of a specific column you want to check.
          </td>
        </tr>
      </table>

      <p><br/></p>

      <hr/>

      To come:

      <p><br/></p>

      <ul className={"default-list"}>
        <li>
          Post a candidacy
        </li>
        <li>
          Move a candidacy
        </li>
        <li>
          Modify a candidacy
        </li>
      </ul>

    </APILayout>

  </PageLayout>);

};

