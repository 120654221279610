
import React, {useContext, useEffect} from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export {PageLayout};

interface IPageLayout {
  pageStatus: 'loading' | 'error' | 'forbidden' | 'loaded',
  page: string,
  children: any,
}

/**
 * Handles page status
 * Loading can be handled deeper for a better user experience
 */
const PageLayout = (props: IPageLayout) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  useEffect(() => {
    appContext.dispatchNavigation({type: "SET_PAGE", value: props.page});
  }, []);
  
  return(<div className={"page-layout"}>
    <SwitchPageStatus {...props} />
  </div>);
};

function SwitchPageStatus(props: IPageLayout) {
  
  switch(props.pageStatus) {
    
    case 'loading':
      return(<>Loading...</>);
      
    case 'forbidden':
      return(<>Forbidden</>);
      
    case 'loaded':
      return props.children;
      
    default:
    case 'error':
      return(<>Error</>);
  }
}