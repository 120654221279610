
import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APIOther};

const APIOther = (props: any) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  return(<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>
      Other
    </APILayout>

  </PageLayout>);

};

