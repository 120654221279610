import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APILandingPage};

const APILandingPage = (props: any) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  return (<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>

      <h1 className={"api-layout__title"}>
        API Reference
      </h1>

      <p className={"text--minified"}>Last doc update: 15/11/2020</p>

      <p><br/><br/></p>

      <p>
        Myshortlist's REST API gives you control over most of your data. This reference guide helps you to implement the
        API. All answers are in json.
        This API is stateless. All requests are validated against an individual API token. This token is delivered by
        Myshortlist's team to accounts owners. Ask your token via the platform.
      </p>

      <p><br/></p>

      <div className={"warning"}>
        <div className={"warning__icon"}>!</div>
        <p><br/></p>
        <ul className={"default-list"}>
          <li>
            Your token is individual. Do not share, do not hard-code it. We advise to use environment variables.
          </li>
          <li>
            Via the API you can access and modify all your account's informations. Be careful on what you do with it. An
            individual sandbox will be available in the future.
          </li>
        </ul>
      </div>

      <p><br/></p>

      <h1 className={"api-layout__title_2"}>
        Use cases
      </h1>

      This api can be used to get data or send data programmatically to your account. It can be used for the following
      situations:

      <p><br/></p>

      <ul className={"default-list"}>
        <li>
          Multi-post jobs TO or FROM your account,
        </li>
        <li>
          Move candidacies or candidates FROM or TO your account,
        </li>
        <li>
          Create addons for web browsers.
        </li>
      </ul>

      <p><br/></p>

      This list only contains examples of use-cases.

      <p><br/></p>

      <h1 className={"api-layout__title_2"}>
        Request's URL and headers
      </h1>

      <ul className={"default-list"}>
        <li>All requests mus be done to the url: <span
          className={"text--i text--label--grey"}>https://public-api.myshortlist.co</span></li>
        <li>All requests are validated against your token. Therefore it must be included in the headers of your request
          under the <span className={"text--i text--label--grey"}>authorization</span> label.
        </li>
      </ul>

      <p><br/></p>

      <h1 className={"api-layout__title_2"}>
        Test a request
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /ping
      </div>

      <p><br/></p>

      This request is successful when you receive "pong". At the time of writing, there is no sandbox available.


      <p><br/></p>

      <h1 className={"api-layout__title_2"}>
        Answers
      </h1>

      All answers are encoded in <span className={"text--i"}>json</span>.

      <p><br/></p>

      <h1 className={"api-layout__title_2"}>
        Need more ?
      </h1>

      At the time of writting, the API can only do basic operations. Contact the team to get more specific endpoints.

      <p><br/></p>
    </APILayout>

  </PageLayout>);

};

