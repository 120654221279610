
import React, {useContext} from "react";
import {ILoginAnswer, login} from "../../utils/login";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import {APILayout} from "../sharedComponents/layouts/APILayout";

export {APITeams};

const APITeams = (props: any) => {

  const appContext: IAppContext = useContext(AppContext) as IAppContext;

  return(<PageLayout pageStatus={"loaded"} page={props.page}>

    <APILayout>

      <h1 className={"api-layout__title"}>
        Teams
      </h1>

      A <span className={"text--i text--label--grey"}>team</span> is an entity holding <span className={"text--i text--label--grey"}>team_members</span>.

      <p><br/></p>

      <hr/>

      <h1 className={"api-layout__title_2"}>
        Get all teams attached to your business
      </h1>

      <div className={"method method--get"}>
        GET
      </div>

      <div className={"method method--url"}>
        /teams
      </div>

      <p><br/></p>

      Header: The request do not need headers other than the token.

      <p><br/></p>

      Answer:

      <p><br/></p>

      <table>
        <tr>
          <td>
            teams
          </td>
          <td>
            array
          </td>
          <td>
            Contains an array of all teams
          </td>
        </tr>

        <tr>
          <td>
            team_id
          </td>
          <td>
            string
          </td>
          <td>
            12 bites ID, unique to every team
          </td>
        </tr>

        <tr>
          <td>
            name
          </td>
          <td>
            string
          </td>
          <td>
            Name of the team
          </td>
        </tr>

        <tr>
          <td>
            members
          </td>
          <td>
            number
          </td>
          <td>
            Number of team members
          </td>
        </tr>

        <tr>
          <td>
            pending_invites
          </td>
          <td>
            number
          </td>
          <td>
            Number of pending invites
          </td>
        </tr>
      </table>





      <p><br/><br/></p>

      <hr/>

      To come:

      <p><br/></p>

      <ul className={"default-list"}>
        <li>
          Get all team members of a team
        </li>
        <li>
          Create a new team
        </li>
        <li>
          Move a team member from a team to another
        </li>
      </ul>

    </APILayout>

  </PageLayout>);

};

